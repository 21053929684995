<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.store" />
    <b-card :title="$t('g.techEvaluationsReviews')" v-else>
      <!-- form -->
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <b-col
              md="6"
              v-for="(item, index) in Object.keys(form_data)"
              :key="index"
            >
              <!-- * -->

              <b-col md="12" v-if="index % 2 === 0">
                <b-form-group :label="$t(`g.${item}`)">
                  <validation-provider
                    #default="{ errors }"
                    :name="item"
                    rules="required"
                  >
                    <b-form-select
                      v-model="form_data[item]"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="available_options"
                      label="text"
                      :state="errors[0] ? false : null"
                    />

                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12" v-else>
                <b-form-group :label="$t(`g.${item}`)">
                  <b-form-input v-model="form_data[item]" />
                </b-form-group>
              </b-col>

              <!-- ** -->
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t(`g.submitDate`)">
                <validation-provider
                  #default="{ errors }"
                  name="submit date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="submit_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
            <!-- ** Submit Button -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, confirmed, min } from "@validations";
import flatPickr from "vue-flatpickr-component";

import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BForm,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    Loading,
    Error,
    flatPickr,
    ContentNotView,
  },
  data() {
    return {
      form_data: {
        is_cert_request_accepted: null,
        cert_request_reject_reason: null,
        is_shipping_accepted: null,
        shipping_reject_reason: null,
        is_comercial_record_accepted: null,
        comercial_record_reject_reason: null,
        is_supplying_accepted: null,
        supplying_reject_reason: null,
        is_factory_accepted: null,
        factory_reject_reason: null,
        is_tech_evaluation_report_accepted: null,
        tech_evaluation_report_reject_reason: null,
        is_cert_request_report_accepted: null,
        cert_request_report_reject_reason: null,
        is_invoice_accepted: null,
        invoice_reject_reason: null,
      },
      submit_date: new Date(),
      available_options: [
        {
          value: null,
          text: this.$t("g.Please_Select_Status"),
          disabled: true,
        },
        {
          value: 1,
          text: this.$t("g.acceptable"),
        },
        {
          value: 0,
          text: this.$t("g.notAcceptable"),
        },
      ],
      isEdit: false,
      isLoading: true,
      isError: null,
      currentId: null,
      techId: null,
      subPermission: {},
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === "AddFinalReviews") {
        this.handleWatchRoutePage();
      }
    },
  },

  created() {
    this.currentId = this.$route.params.id;
    // if (this.$route.name === "EditFinalReviews") {
    //   this.isEdit = true;
    //   this.isLoading = true;
    //   this.techId = this.$route.params.techId;

    // this.getFinalReviews();
    // }
  },

  beforeMount() {
    setTimeout(() => {
      this.pageStatus(false, false);
      store.dispatch("GET_PERMISSION", "finalReviews");
      this.subPermission = store.state.permissions.sub;
    }, 2000);
  },

  methods: {
    //  GET DATA SUPPLIER IF isEdit true
    // getFinalReviews() {
    //   this.$http
    //     .get(
    //       `admin/serviceRequests/${this.currentId}/finalReviews/${this.techId}`
    //     )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         const data = response.data.data;
    //         console.log(data);

    //         this.pageStatus(false, false);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.pageStatus(false, true);
    //     });
    // },

    // Update page data when it changes
    handleWatchRoutePage() {
      this.isEdit = false;
      this.$refs.form.reset();
      this.clearForm();
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    // Changes the state of the page according to the return of the data => ( success Or error )
    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },
    //  Clear Form Data
    clearForm() {
      this.form_data.is_cert_request_accepted = null;
      this.form_data.cert_request_reject_reason = null;
      this.form_data.is_shipping_accepted = null;
      this.form_data.shipping_reject_reason = null;
      this.form_data.is_comercial_record_accepted = null;
      this.form_data.comercial_record_reject_reason = null;
      this.form_data.is_supplying_accepted = null;
      this.form_data.supplying_reject_reason = null;
      this.form_data.is_factory_accepted = null;
      this.form_data.factory_reject_reason = null;
      this.form_data.is_tech_evaluation_report_accepted = null;
      this.form_data.tech_evaluation_report_reject_reason = null;
      this.form_data.is_cert_request_report_accepted = null;
      this.form_data.cert_request_report_reject_reason = null;
      this.form_data.is_invoice_accepted = null;
      this.form_data.invoice_reject_reason = null;
    },
    // Send the modified data
    // submitEdit() {
    //   this.$refs.form.validate().then((success) => {
    //     if (success) {

    //       const body = {
    //         ...this.form_data,
    //         submit_date: this.submit_date,
    //       };
    //       // append data to FormData object before sending to backend
    //       let formData = new FormData();
    //       for (const item in body) {
    //         formData.append(item, body[item]);
    //       }
    //       this.$http
    //         .post(
    //           `admin/serviceRequests/${this.currentId}/finalReviews/${this.techId}?_method=put`,
    //           formData
    //         )
    //         .then((res) => {
    //           this.makeToast(
    //             "success",
    //             this.$t("g.send.successBody"),
    //             this.$t("g.send.successTitle")
    //           );
    //           setTimeout(() => {
    //             this.$router.push({ name: "ListFinalReviews" });
    //           }, 500);
    //         })
    //         .catch((err) => {
    //           for (const key in err.response.data.errors) {
          //   this.makeToast(
          //     "danger",
          //     err.response.data.errors[key][0],
          //     this.$t("g.send.errorTitle")
          //   );
          // }
          // console.log(err);
    //         });
    //     } else {
    //       this.makeToast(
    //         "warning",
    //         this.$t("g.send.invalidInputBody"),
    //         this.$t("g.send.invalidInput")
    //       );
    //     }
    //   });
    // },
    //  Submit new user
    submitNewAddition() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          console.log(this.form_data);

          const data = {
            ...this.form_data,
            submit_date: this.submit_date,
          };
          // append data to FormData object before sending to backend
          let formData = new FormData();
          for (const item in data) {
            formData.append(item, data[item]);
          }
          this.$http
            .post(
              `admin/serviceRequests/${this.currentId}/finalReviews`,
              formData
            )
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "ListFinalReviews" });
              }, 1000);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },

    //  Submit Function
    validationForm() {
      if (this.isEdit) {
        return this.submitEdit();
      }
      this.submitNewAddition();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

[dir] textarea.form-control {
  padding: 0.5rem 0.5rem !important;
}
</style>
